/**
 * 登录状态管理
 */

import { userInfo } from '@/api/user';

export default {
    namespaced: true,
    state: {
        // 当前登录用户信息
        info: null,
    },
    mutations: {
        // 设置登录用户的信息
        setUserInfo(state, info) {
            state.info = info;
        },
    },
    actions: {
        /**
         * 请求用户信息、权限、角色、菜单
         */
        async fetchUserInfo({ commit }) {
            const result = await userInfo().catch(() => { });
            if (!result) {
                return null;
            }
            // 用户信息
            commit('setUserInfo', result);
            return result;
        },
        /**
         * 更新用户信息
         */
        setInfo({ commit }, value) {
            commit('setUserInfo', value);
        },
    }
};
