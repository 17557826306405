
// 接口地址
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

// 项目名称
export const PROJECT_NAME = process.env.VUE_APP_NAME;

// 不需要登录的路由
export const WHITE_LIST = ['/login', '/reg', '/reset'];

// token 传递的 header 名称
export const TOKEN_HEADER_NAME = 'Authorization';

// token 存储的名称
export const TOKEN_STORE_NAME = 'unimind_access_token';

// 刷新路由的路由地址
export const REDIRECT_PATH = '/redirect';

// 外层布局的路由地址
export const LAYOUT_PATH = '/';

export const OSS_REGION = 'oss-cn-guangzhou'

export const OSS_BUCKET = 'unimind'

export const OSS_URL = 'https://unimind.oss-cn-guangzhou.aliyuncs.com'

