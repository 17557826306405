import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/css/element-variables.scss'
import hljs from 'highlight.js';
import 'highlight.js/styles/a11y-dark.css'
//导入自定义图标
import'./assets/font/iconfont.css'
//导入全局样式表
import'./assets/css/global.css'

//创建v-highlight全局指令
Vue.directive('hljs',function (el) {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block)=> {
    hljs.highlightElement(block)
  })
})

// import MarkdownIt from 'markdown-it'
// const md = new MarkdownIt()
// Vue.prototype.$md = md
// import { html5Media } from 'markdown-it-html5-media'
// import MarkdownItKatex from '@iktakahiro/markdown-it-katex'
// md.use(html5Media)
// md.use(MarkdownItKatex)


// import MarkdownItVue from 'markdown-it-vue'
// import 'markdown-it-vue/dist/markdown-it-vue.css'
// import 'markdown-it-vue/node_modules/highlight.js/styles/a11y-dark.css'
// Vue.use(MarkdownItVue)

// import { marked } from 'marked';
// marked.setOptions({
//   mangle: false,
//   headerIds: false
// })
// Vue.prototype.$marked = marked

// import katex from "katex";
// import "katex/dist/katex.css";
// Vue.prototype.$katex = katex


// import mavonEditor from 'mavon-editor'
// import 'mavon-editor/dist/css/index.css'
// Vue.use(mavonEditor)

// import audio from 'vue-mobile-audio'
// Vue.use(audio)

import VueAudioRecorder from 'vue-audio-recorder'
Vue.use(VueAudioRecorder)

import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
