import request from '@/utils/request';

/**
 * 获取用户信息
 */
export async function userInfo() {
    const res = await request.get('/user/info');
    if (res.data.status === 200) {
        return res.data.data;
    }
    return Promise.reject(new Error(res.data.msg));
}

/**
 * 修改昵称
 * @param {Object} data
 */
export async function userNicknameSave(data) {
	const res = await request.post('/user/nickname/save', data)
	if (res.data.status === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.msg));
}

/**
 * 用户反馈
 * @param {Object} data
 */
export async function feedback(data) {
	const res = await request.post('/feedback/post', data)
	if (res.data.status === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.msg));
}

/**
 * 次数明细列表
 * @param {Object} data
 */
export async function billList(params) {
	const res = await request.get('/user/bill/list', {
        params
    })
	if (res.data.status === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.msg));
}