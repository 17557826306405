import Vue from 'vue'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'
import VueRouter from 'vue-router'
import store from '@/store';
import { getToken } from '@/utils/token-util';
import { WHITE_LIST, REDIRECT_PATH, LAYOUT_PATH } from '@/config/setting';

NProgress.configure({
	showSpinner: false // 取消插件自带的loading小菊花
})

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
	{
		path: '/',
		component: () => import('@/views/home/index.vue'),
		children: [
			{
				path: '/',
				component: () => import('@/views/template/recommend.vue'),
			},
			{
				path: '/user',
				component: () => import('@/views/user/index.vue'),
			},
			{
				path: '/feedback',
				component: () => import('@/views/feedback/index.vue'),
			},
			{
				path: '/template',
				component: () => import('@/views/template/index.vue'),
			},
			{
				path: '/bill',
				component: () => import('@/views/bill/index.vue'),
			},
			{
				path: '/ask/:session_id/:template_id?',
				component: () => import('@/views/ask/index.vue'),
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/login.vue'),
	},
	{
		path: '/reg',
		name: 'reg',
		component: () => import('@/views/login/reg.vue'),
	},
	{
		path: '/reset',
		name: 'reset',
		component: () => import('@/views/login/reset.vue'),
	}
]

const router = new VueRouter({
	routes,
	mode: 'history'
})

/**
 * 路由守卫
 */
router.beforeEach((to, from, next) => {
	if (!from.path.includes(REDIRECT_PATH)) {
		NProgress.start();
	}
	// 判断是否登录
	if (getToken()) {
		// 还未注册动态路由则先获取
		if (!store.state.user.info) {
			store
				.dispatch('user/fetchUserInfo')
				.then(res => {
					if (res) {
						next({...to, replace: true});
					}
				})
				.catch((e) => {
					console.error(e);
					next();
				});
		} else {
			next();
		}
	} else if (WHITE_LIST.includes(to.path)) {
		next();
	} else {
		next({
			path: '/login',
			query: to.path === LAYOUT_PATH ? {} : { from: to.path }
		});
	}
});


router.afterEach((to) => {
	if (!to.path.includes(REDIRECT_PATH) && NProgress.isStarted()) {
		setTimeout(() => {
			NProgress.done(true);
		}, 200);
	}
});

export default router
