import router from '@/router';
import { removeToken } from '@/utils/token-util';

const BASE_URL = process.env.BASE_URL;

/**
 * 退出登录
 * @param from 是否使用路由跳转
 * @param from 登录后跳转的地址
 */
export function logout(route, from) {
    removeToken();
    if (route) {
      router.push({
        path: '/login',
        query: from ? { from } : void 0
      });
    } else {
      // 这样跳转避免再次登录重复注册动态路由
      location.replace(BASE_URL + 'login' + (from ? '?from=' + from : ''));
    }
  }