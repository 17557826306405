<template>
	<div id="app">
		<!-- 路由占位符 -->
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			regTimer: null,
			regCountDown: parseInt(localStorage.getItem('unimindRegCountDown')),
			resetTimer: null,
			resetCountDown: parseInt(localStorage.getItem('unimindResetCountDown'))
		}
	},
	mounted() {
		this.startRegTimer()
		this.startResetTimer()
	},
	methods: {
		startRegTimer() {
			this.regTimer = setInterval(() => {
				if (this.regCountDown > 0) {
					this.regCountDown--;
					localStorage.setItem('unimindRegCountDown', this.regCountDown)
				} else {
					clearInterval(this.regTimer); // 倒计时结束，停止计时器
				}
			}, 1000)
		},
		startResetTimer() {
			this.resetTimer = setInterval(() => {
				if (this.resetCountDown > 0) {
					this.resetCountDown--;
					localStorage.setItem('unimindResetCountDown', this.resetCountDown)
				} else {
					clearInterval(this.resetTimer); // 倒计时结束，停止计时器
				}
			}, 1000)
		}
	}
}
</script>

<style>
#nprogress .bar {
	background: #23BB80 !important;
}
</style>